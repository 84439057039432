import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from 'components/common/Layout';
import Hero from 'components/Subpage/Hero';
import { imageProp } from 'utils/types';
import Content from 'components/Subpage/Content';
import useContent from 'utils/useContent';
import Seo from 'components/common/SEO';

const Subpage = ({ data }) => {
  const { header, headerDarkMode, title, subtitle, image, body, footer }
    = data.prismicSubpage.data;
  const components = useContent(body);

  return (
    <Layout header={header} headerDarkMode={headerDarkMode} footer={footer}>
      <Hero title={title} subtitle={subtitle} image={image} />
      <Content>{components}</Content>
    </Layout>
  );
};

export const Head = ({ pageContext, location }) => (
  <Seo metatags={pageContext.metatags} location={location} />
);

Subpage.propTypes = {
  data: PropTypes.shape({
    prismicSubpage: PropTypes.shape({
      data: PropTypes.shape({
        header: PropTypes.shape({
          document: PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
        }),
        image: imageProp,
        headerDarkMode: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        body: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
          }),
        ),
        footer: PropTypes.shape({
          document: PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
  }),
};

export const query = graphql`
  query SubpageQuery($uid: String!) {
    prismicSubpage(uid: { eq: $uid }) {
      data {
        title
        subtitle
        headerDarkMode: header_dark_mode
        image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(width: 588, formats: [AVIF, WEBP])
            }
          }
        }
        header {
          document {
            ...Header
          }
        }
        footer {
          document {
            ...Footer
          }
        }
        body {
          ...ContentBox
          ...ContentRichText
        }
      }
    }
  }
`;

export default Subpage;
