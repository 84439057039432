import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';

import { StyledButton, StyledContainer, StyledText } from './styles';

const ContentBox = ({ data }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.4 });

  const { text, internalLink, externalLink } = data;
  const isButton = !!(internalLink || externalLink.url);

  return (
    <StyledContainer ref={ref} $inView={inView}>
      <StyledText tag="p" type="h3">
        {text}
      </StyledText>
      {isButton && (
        <StyledButton path={internalLink || externalLink.url} color="secondary">
          More info
        </StyledButton>
      )}
    </StyledContainer>
  );
};

ContentBox.propTypes = {
  data: PropTypes.shape({
    internalLink: PropTypes.string,
    externalLink: PropTypes.shape({
      url: PropTypes.string,
    }),
    text: PropTypes.string.isRequired,
  }),
};

export const query = graphql`
  fragment ContentBox on PrismicSubpageDataBodyContentBox {
    type: slice_type
    id
    content_box_content: primary {
      text
      internalLink: internal_link
      externalLink: external_link {
        url
      }
    }
  }
`;

export default ContentBox;
