import React from 'react';

import ContentBox from 'components/Subpage/ContentBox';
import ContentRichText from 'components/Subpage/ContentRichText';

const componentTypes = {
  ['content_box']: ContentBox,
  ['rich_text']: ContentRichText,
};

const useContent = content =>
  content.map(slice => {
    const { type, id } = slice;
    const Component = componentTypes[type];
    const contentKey = `${type}_content`;
    const data = slice[contentKey];

    if (Component) {
      return <Component key={id} data={data} image={data?.image} />;
    }
  });

export default useContent;
