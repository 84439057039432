import styled, { css } from 'styled-components';

import { colors, media } from 'utils/variables';
import Heading from 'components/_shared/Heading/Heading';
import Button from 'components/_shared/Button/Button';

const dynamicSectionStyles = ({ $inView }) => css`
  opacity: ${$inView ? 1 : 0};
  transform: ${$inView ? 'translateY(-30px)' : 'translateY(0)'};
`;

export const StyledContainer = styled.div`
  box-shadow: 0px 0px 15px rgba(37, 143, 103, 0.1);
  border-radius: 16px;
  background-color: ${colors.white};
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 25px 30px;
  max-width: 550px;
  transition: opacity, transform;
  transition-duration: 0.6s;

  :nth-child(2n) {
    background-color: ${colors.light};
  }

  @media (min-width: ${media.s}) {
    padding: 25px 60px 25px 60px;
    max-width: none;
  }

  @media (min-width: ${media.m}) {
    padding: 40px 119px 35px 112px;
  }

  ${dynamicSectionStyles};
`;

export const StyledText = styled(Heading)`
  color: ${colors.greenDark};
`;

export const StyledButton = styled(Button)`
  margin-top: 35px;
`;
