import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import {
  StyledSection,
  StyledTitle,
  StyledSubTitle,
  StyledImage,
} from './styles';

import { imageProp } from 'utils/types';
import Container from 'components/_shared/Container';

const Hero = ({ title, subtitle, image }) => {
  const { alt } = image;
  const photo = getImage(image.localFile);
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <StyledSection ref={ref} $inView={inView}>
      <Container>
        <StyledSubTitle type="h4">{subtitle}</StyledSubTitle>
        <StyledTitle>{title}</StyledTitle>
        {photo && <StyledImage image={photo} alt={alt || 'hero image'} />}
      </Container>
    </StyledSection>
  );
};

Hero.propTypes = {
  image: imageProp,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Hero;
