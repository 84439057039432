import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import Paragraph from 'components/_shared/Paragraph/Paragraph';
import { colors, fontWeights, fonts } from 'utils/variables';

const dynamicSectionStyles = ({ $inView }) => css`
  opacity: ${$inView ? 1 : 0};
  transform: ${$inView ? 'translateY(-30px)' : 'translateY(0)'};
`;

export const StyledContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 30px;
  transition: opacity, transform;
  transition-duration: 0.6s;

  p ~ ul {
    margin-top: -15px;
  }

  ${dynamicSectionStyles}
`;

export const StyledParagraph = styled(Paragraph)`
  color: ${colors.grayLight};
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const StyledList = styled.ul`
  margin: 0;
  margin-top: -17px;
  padding-left: 25px;
  font-weight: ${fontWeights.light};
  font-family: ${fonts.montserrat};
  color: ${colors.grayLight};
  font-size: 1rem;
  margin-bottom: 30px;

  li {
    margin-bottom: 10px;

    :last-child {
      margin: 0;
    }
  }
`;

export const StyledImage = styled(GatsbyImage)`
  width: 80%;
  height: 300px;
  border-radius: 20px;

  &.gatsby-image-wrapper-constrained {
    margin: 30px auto 0;
    display: block;
  }
`;
