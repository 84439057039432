import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import Heading from 'components/_shared/Heading/Heading';
import { colors, fontWeights, media } from 'utils/variables';

const dynamicSectionStyles = ({ $inView }) => css`
  opacity: ${$inView ? 1 : 0};
  transform: ${$inView ? 'translateY(-70px)' : 'translateY(0)'};
`;

export const StyledSection = styled.section`
  transition: opacity, transform;
  transition-duration: 0.6s;
  margin-top: 150px;

  @media (min-width: ${media.s}) {
    margin-top: 250px;
  }

  @media (min-width: ${media.m}) {
    margin-top: 300px;
  }

  ${dynamicSectionStyles};
`;

export const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 380px;
  border-radius: 19px;
  margin-top: 30px;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
`;

export const StyledSubTitle = styled(Heading)`
  color: ${colors.secondary};
  font-weight: ${fontWeights.light};
`;

export const StyledTitle = styled(Heading)`
  margin-top: 10px;
  text-transform: uppercase;
`;
