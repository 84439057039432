import React from 'react';

import { StyledSection } from './styles';

import Container from 'components/_shared/Container';
import { childrenProp } from 'utils/types';

const Content = ({ children }) => (
  <StyledSection>
    <Container>{children}</Container>
  </StyledSection>
);

Content.propTypes = {
  children: childrenProp,
};

export default Content;
