import React from 'react';
import PropTypes from 'prop-types';
import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';

import {
  StyledContainer,
  StyledParagraph,
  StyledList,
  StyledImage,
} from './styles';

import RichText from 'components/_shared/RichText';
import Heading from 'components/_shared/Heading/Heading';
import { richTextProp, imageProp } from 'utils/types';

const ContentRichText = ({ data, image }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const { title, text } = data;
  const { localFile, alt } = image || {};

  const sectionImage = getImage(localFile);

  return (
    <StyledContainer ref={ref} $inView={inView}>
      {title && (
        <Heading type="h3" tag="p">
          {title}
        </Heading>
      )}
      {sectionImage && (
        <StyledImage image={sectionImage} alt={alt || 'section image'} />
      )}
      <RichText
        text={text.richText}
        components={{
          paragraph: ({ children }) => (
            <StyledParagraph>{children}</StyledParagraph>
          ),
          list: ({ children }) => <StyledList>{children}</StyledList>,
        }}
      />
    </StyledContainer>
  );
};

ContentRichText.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.shape({
      richText: richTextProp,
    }),
  }),
  image: imageProp,
};

export const query = graphql`
  fragment ContentRichText on PrismicSubpageDataBodyRichText {
    type: slice_type
    id
    rich_text_content: primary {
      title: text_title
      text {
        richText
      }
      image: rich_text_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(width: 588, formats: [AVIF, WEBP])
          }
        }
      }
    }
  }
`;

export default ContentRichText;
